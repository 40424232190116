import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/common/layout/layout';
import SEO from '../components/common/layout/seo';
import Navigation from '../components/common/navigation/navigation';

const NotFoundPage = () => (
  <Layout>
    <Navigation />
    <Container>
      <SEO title="404: Not found" />
      <SubContainer>
        <h1 style={{ fontSize: '72px' }}>404</h1>
        <h1>Page Not Found</h1>

        <p>
          The page you are looking for might have been removed, had its name changed, or is
          temporarily unavailable
        </p>
        <HomeButton to="/">Return Home</HomeButton>
      </SubContainer>
    </Container>
  </Layout>
);

export default NotFoundPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f8f8;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  text-align: center;
  p {
    color: ${(props) => props.theme.color.secondary};
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
  h1 {
    margin-bottom: 10px;
  }
`;

const HomeButton = styled(Link)`
  font-weight: 500;
  font-size: 18px;
  color: ${(props) => props.theme.color.secondary};
  text-decoration: none;
  display: block;

  cursor: pointer;
  white-space: nowrap;
  //background: ${(props) => props.theme.color.secondary};
  border-radius: 6px;
  padding: 5px 15px;
  border-width: 3px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`;
